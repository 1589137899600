<template>
  <div>
    <h2>Recent Transactions</h2>

    <div class="unavailable-box" v-if="payments.length === 0">
      No recent transactions available
    </div>

    <div
      class="block tabular payment"
      v-bind:key="item.id"
      v-for="item in payments"
    >
      <div class="row">
        <div class="col-2">
          <span class="icon" style="position:absolute; left: 0px;">
            <font-awesome-icon
              v-if="item.arrangement.method === 'cash'"
              icon="money-bill-alt"
            />
            <font-awesome-icon
              v-if="item.arrangement.method === 'card'"
              icon="credit-card"
            />
          </span>
        </div>
        <div class="col-6">
          <div class="head">
            <span v-if="item.arrangement.method === 'cash'">Shop</span
            ><span v-else>Online</span> Payment
          </div>
          <div class="sub">
            {{ item.transacted_at | moment("MMMM Do YYYY HH:mm") }}
          </div>
        </div>
        <div class="col-4">
          <div class="price">£{{ item.payment }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  name: "RecentTransactions",
  props: {
    payments: {
      type: Array,
      required: true
    }
  },
  components: {
    FontAwesomeIcon
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";
</style>
