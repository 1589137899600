<template>
  <section>
    <div class="container">
      <div class="row" v-if="dashboard.balances.length > 0">
        <div class="col-md-12">
          <h2>Balance History</h2>

          <div class="row">
            <div
              class="col-md-3 col-6"
              v-bind:key="item.key"
              v-for="item in dashboard.meters"
              @click="changeUtility(item.key)"
            >
              <UtilityBlock
                v-if="item.reads.last_read"
                :id="item.key"
                :active-utility="activeUtility.key"
                :utility="item.unit_short"
                :read="item.reads.last_read"
              ></UtilityBlock>
            </div>
          </div>

          <div class="row tariff-info">
            <div class="col-md-12">
              <h5>
                {{ getElement("name", activeUtility.unit_short) }} Tariff
                Information
              </h5>
            </div>
            <div class="col-md-12">
              <p>
                Serial Number: <strong>{{ activeUtility.serial }}</strong>
              </p>
            </div>
            <div class="col-md-12">
              <p>
                Unit Rate: <strong>£{{ activeUtility.unit_price }}</strong>
              </p>
            </div>
            <div class="col-md-12">
              <p>
                Standing Charge:
                <strong>£{{ activeUtility.standing_charge }}</strong>
              </p>
            </div>
          </div>

          <div class="block" style="margin-top: 30px;">
            <div class="chart" ref="chartdiv"></div>
          </div>
        </div>

        <div class="col-md-12" v-if="activeUtility.reads.reads.length > 0">
          <div style="margin-top: 30px;">
            <b-table
              style="border-left: 1px solid #ddd; border-right: 1px solid #ddd; border-bottom: 1px solid #ddd;"
              class="meter-table"
              striped
              hover
              show-empty
              sticky-header="200px"
              :items="activeUtility.reads.reads"
              :fields="readFields"
              empty-text="No reads"
              sort-by="user_date"
              :sort-desc="true"
            >
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UtilityBlock from "@/views/Components/Dashboard/UtilityBlock";

import { utilities } from "@/components/mixins/utilities";

export default {
  name: "ReadBlock",
  mixins: [utilities],
  props: {
    dashboard: {
      type: Object,
      required: true
    }
  },
  components: {
    UtilityBlock
  },
  data() {
    return {
      chart: null,
      activeUtility: null,
      readFields: [
        { key: "user_date", label: "Date", sortable: true },
        { key: "data.read", label: "Read", sortable: true },
        { key: "data.consumption", label: "Consumption", sortable: true }
      ]
    };
  },
  created() {
    this.setup();
  },
  methods: {
    setup() {
      if (this.dashboard.meters.length === 0) {
        throw "Invalid utility";
      }

      this.activeUtility = this.dashboard.meters[0];

      this.$nextTick(() => {
        this.loadGraph();
      });
    },
    changeUtility(key) {
      if (key === this.activeUtility.key) {
        return;
      }

      this.activeUtility = this.dashboard.meters[key];
      if (this.chart) {
        this.chart.dispose();
      }
      this.$nextTick(() => {
        if (this.activeUtility.reads.reads.length > 0) {
          this.loadGraph();
        }
      });
    },
    loadGraph() {
      Promise.all([
        import("@amcharts/amcharts4/core"),
        import("@amcharts/amcharts4/charts")
      ]).then(modules => {
        const am4core = modules[0];
        const am4charts = modules[1];

        let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
        am4core.options.minPolylineStep = 5;
        chart.responsive.enabled = true;

        // Increase contrast by taking evey second color
        //chart.colors.step = 2;

        const meterData = this.dashboard.balances;
        const friendlyCredit = this.dashboard.allowed_credit;

        // Add data
        chart.data = generateActualData(this.activeUtility.key);

        chart.colors.list = [
          am4core.color("#83c55d"),
          am4core.color("#5d3791")
        ];

        // Create axes

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        //dateAxis.renderer.minGridDistance = 50;
        dateAxis.groupData = true;
        dateAxis.groupCount = 500;

        // Create series
        function createAxisAndSeries(
          field,
          name,
          opposite,
          bullet,
          isColumn,
          isFixedLines
        ) {
          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

          let series = isColumn
            ? chart.series.push(new am4charts.ColumnSeries())
            : chart.series.push(new am4charts.LineSeries());

          series.dataFields.valueY = field;
          series.dataFields.dateX = "date";
          series.strokeWidth = 2;
          series.yAxis = valueAxis;
          series.name = name;
          series.tooltipText = "{name}: [bold]{valueY}[/]";
          series.minBulletDistance = 15;

          let interfaceColors = new am4core.InterfaceColorSet();
          let triangle = "";
          let rectangle = "";

          switch (bullet) {
            case "triangle":
              bullet = series.bullets.push(new am4charts.Bullet());
              bullet.width = 12;
              bullet.height = 12;
              bullet.horizontalCenter = "middle";
              bullet.verticalCenter = "middle";

              triangle = bullet.createChild(am4core.Triangle);
              triangle.stroke = interfaceColors.getFor("background");
              triangle.strokeWidth = 2;
              triangle.direction = "top";
              triangle.width = 12;
              triangle.height = 12;
              break;
            case "rectangle":
              bullet = series.bullets.push(new am4charts.Bullet());
              bullet.width = 10;
              bullet.height = 10;
              bullet.horizontalCenter = "middle";
              bullet.verticalCenter = "middle";

              rectangle = bullet.createChild(am4core.Rectangle);
              rectangle.stroke = interfaceColors.getFor("background");
              rectangle.strokeWidth = 2;
              rectangle.width = 10;
              rectangle.height = 10;
              break;
            default:
              bullet = series.bullets.push(new am4charts.CircleBullet());
              bullet.circle.stroke = interfaceColors.getFor("background");
              bullet.circle.strokeWidth = 2;
              break;
          }

          valueAxis.renderer.line.strokeOpacity = 1;
          valueAxis.renderer.line.strokeWidth = 2;
          valueAxis.renderer.line.stroke = series.stroke;
          valueAxis.renderer.labels.template.fill = series.stroke;
          valueAxis.renderer.opposite = opposite;
          valueAxis.renderer.grid.template.disabled = true;

          if (isFixedLines) {
            let range = valueAxis.axisRanges.create();
            range.value = 0;
            range.grid.stroke = am4core.color("#FF7400");
            range.grid.strokeWidth = 2;
            range.grid.strokeOpacity = 1;
            range.label.inside = true;
            range.label.text = "Negative Credit (£0.00)";
            range.label.fill = range.grid.stroke;
            range.label.verticalCenter = "bottom";

            let range2 = valueAxis.axisRanges.create();
            range2.value = -Math.abs(friendlyCredit);
            range2.grid.stroke = am4core.color("#CC0000");
            range2.grid.strokeWidth = 2;
            range2.grid.strokeOpacity = 1;
            range2.label.inside = true;
            range2.label.text = "Disconnect (-£" + friendlyCredit + ")";
            range2.label.fill = range2.grid.stroke;
            range2.label.verticalCenter = "top";
          }

          let scrollbarX = new am4charts.XYChartScrollbar();
          scrollbarX.series.push(series);
          chart.scrollbarX = scrollbarX;
        }

        createAxisAndSeries(
          "consumption",
          this.activeUtility.unit,
          true,
          "circle",
          true,
          false
        );
        createAxisAndSeries(
          "balance",
          "Balance [[£]]",
          false,
          "circle",
          false,
          true
        );

        // Add legend
        chart.legend = new am4charts.Legend();

        chart.exporting.menu = new am4core.ExportMenu();
        chart.exporting.filePrefix = "dc-export";

        chart.dateFormatter.dateFormat = "MM-dd";

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        function generateActualData(key) {
          let chartData = [];
          const keyReadName = key + "read";
          const keyConsumptionName = key + "consumption";

          meterData.forEach(function(row) {
            let entry = {
              date: new Date(row.date),
              balance: row.data.balance,
              payment: row.data.payment,
              read: row.data[keyReadName],
              consumption: row.data[keyConsumptionName]
            };

            chartData.push(entry);
          });
          return chartData;
        }

        this.chart = chart;
      });
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/dashboard";

.tariff-info {
  padding-left: 10px;

  p {
    text-align: left;
    margin-bottom: 0px;
  }

  h5 {
    text-align: left;
    text-transform: capitalize;
  }
}
</style>
