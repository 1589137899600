<template>
  <div v-if="dashboardLoaded">
    <HeaderOverview
      @refresh="fetchData"
      :dashboard="dashboard"
    ></HeaderOverview>

    <ReadBlock :dashboard="dashboard"></ReadBlock>

    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <RecentTransactions
            :payments="dashboard.payments"
          ></RecentTransactions>
        </div>
        <div class="col-md-6">
          <MakePayment
            :id="payRef"
            :debt-percent="dashboard.debt_percent"
          ></MakePayment>
        </div>
      </div>
    </div>

    <PrePayHelp></PrePayHelp>

    <PrePayNotifications
      @refresh="fetchData"
      :id="payRef"
      :notifications="dashboard.notifications"
    ></PrePayNotifications>

    <PrePayDangerZone
      @refresh="fetchData"
      :id="payRef"
      :dashboard="dashboard"
    ></PrePayDangerZone>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faAlignLeft } from "@fortawesome/free-solid-svg-icons";

import HeaderOverview from "@/views/PrePay/Components/HeaderOverview";
import ReadBlock from "@/views/PrePay/Components/ReadBlock";
import RecentTransactions from "@/views/PrePay/Components/RecentTransactions";
import MakePayment from "@/views/PrePay/Components/MakePayment";
import PrePayNotifications from "@/views/PrePay/Components/PrePayNotifications";
import PrePayDangerZone from "@/views/PrePay/Components/PrePayDangerZone";
import PrePayHelp from "@/views/PrePay/Components/PrePayHelp";

library.add(fas, faSpinner, faAlignLeft);

export default {
  name: "PrePayDashboard",
  props: ["payRef"],
  components: {
    PrePayHelp,
    HeaderOverview,
    ReadBlock,
    RecentTransactions,
    MakePayment,
    PrePayNotifications,
    PrePayDangerZone
  },
  data() {
    return {
      dashboard: {},
      dashboardLoaded: false
    };
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  methods: {
    fetchData() {
      const title = "Fetching Data";
      const subtitle = "Compiling Dashboard Data";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      this.$store
        .dispatch("dashboardPrePayGet", { id: this.payRef })
        .then(response => {
          this.dashboard = response;

          this.dashboardLoaded = true;
        })
        .catch(err => {
          const title = "Error accessing Dashboard";
          const subtitle = err;
          this.$store.dispatch("pushError", { title, subtitle });

          this.$router.push("/");
        })
        .finally(() => {
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/dashboard.scss";
</style>
